
export default {
  data() {
    return {
      pathname: '',
      dialog: false,
      items: [
        {
          name: 'Home',
          path: '/',
          width: 57,
          external: false,
        },
        {
          name: 'Cursos',
          path: '/cursos',
          width: 120,
          external: false,
        },
        {
          name: 'Primeiro Emprego',
          path: '/meu-primeiro-emprego',
          width: 120,
          external: false,
        },
        {
          name: 'Multiversa',
          path: 'https://multiversa.edu.br',
          width: 120,
          external: true,
        },

        {
          name: 'Alunos',
          path: 'https://app3.evoluaeducacao.com.br/Login',
          width: 67,
          external: true,
        },
      ],

      rightDrawer: false,
    }
  },
  methods: {
    isSelected(item) {
      if (!item) return false
      const { path } = this.$route

      return item.path === path
    },
  },
}
