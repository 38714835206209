 /* eslint-disable */
 export default ({ app, store }) => {
    /*
    ** Only run on client-side and only in production mode
    */
    // if (process.env.NODE_ENV !== "production") return
    /*
    ** Initialize Facebook Pixel Script
    */
    if (process.browser) {

        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-JRGGN500GJ');
    }
}