export default ({ $config: { strapiUrl, imagineUrl, isStaging } }, inject) => {
  let dev = process.env.NODE_ENV !== 'production'
  const parseStrapiUrl = (image, query) =>
    dev ? `${strapiUrl}${image}` : `${imagineUrl}/strapi/${image}${query}`

  const parseNuxtUrl = (image, query) => {
    return dev
      ? `http://localhost:3000/${image}`
      : // : isStaging
        // ? `${image}`
        `${imagineUrl}/technos/${image}${query}`
  }

  /**
   *
   * se for strapi imagineURl/strapi
   * se for strapi imagineURl/multiversa
   *
   * @namespace
   * @param {string} image
   * @param {object} options
   * @property {boolean} options.is_strapi  - Should fetch from Strapi
   * @property {number} options.tr          - Trim using ImageKit
   */
  const imagine = (image, options = {}) => {
    let query = ''
    if (!image) {
      return console.error('There are missing images.')
    }

    if ('tr' in options) {
      query = `?tr=w-${options.tr}`
    }

    if ('is_strapi' in options) {
      return parseStrapiUrl(image, query)
    }

    return parseNuxtUrl(image, query)
  }

  inject('imagine', imagine)
}
